<template>
  <v-card flat class="mt-5">
    <v-form class="multi-col-validation">
      <div class="pa-3">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="fullName" label="Nome Completo" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="nationality" label="Nacionalidade" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="maritalStatus" label="Estado Civil" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="activity" label="Atividade" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="cpfcnpj" label="CPF/CNPJ" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="rg" label="RG" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="9">
              <v-text-field v-model="address" label="Endereço Completo" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="complement" label="Complemento" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="email" type="email" label="E-mail" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="telephone" type="tel" label="Telefone" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-2"> Salvar </v-btn>
              <v-btn color="secondary" outlined class="mt-2"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const fullName = ref('Fernando N. Daher')
    const nationality = ref('Brasileiro')
    const maritalStatus = ref('Casado')
    const activity = ref('Empresário')
    const cpfcnpj = ref('021.345.678-90')
    const rg = ref('01.234.567-8')
    const address = ref('R. Vila-Lobos, 325 - Tucano, Londrina - PR, 86047-130')
    const complement = ref('Casa de Esquina')
    const email = ref('fernando@daher.adm.br')
    const telephone = ref('(43) 99928-3941')

    return {
      fullName,
      nationality,
      maritalStatus,
      activity,
      cpfcnpj,
      rg,
      address,
      complement,
      email,
      telephone,
    }
  },
}
</script>