<template>
  <v-card id="user-profile-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <info></info>
      </v-tab-item>

      <v-tab-item>
        <password></password>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiFormTextboxPassword } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import info from './Info.vue'
import password from './Password.vue'

export default {
  components: {
    info,
    password,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Dados Pessoais', icon: mdiAccountOutline },
      { title: 'Alterar Senha', icon: mdiFormTextboxPassword },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiFormTextboxPassword,
      },
    }
  },
}
</script>
